import i18next from "i18next"
import React from "react"
import { Platform, StyleSheet } from "react-native"

import { ActionButtons } from "./ActionButtons"
import {
  AlertDialog,
  AlertDialogScrollView,
  AppIcons,
  Background,
  Box,
  Button,
  CancelButton,
  Container,
  FlatList,
  ICON_SIZES,
  ListItemCard,
  renderIconFromIconProp,
  Row,
  SubmitButton,
  Switch,
  TextWithIcon,
  TitleWithIcon,
  useBreakpointValue,
} from "./components"
import { SPACING } from "./components/theme"
import { DeviceConnectionDisplay } from "./DeviceConnectionDisplay"
import {
  deleteEventActionTriggersAsync,
  updateDeviceConnectionAsync,
} from "./farmhq-api"
import * as Models from "./models"
import { NoListItems } from "./NoListItems"
import i18n from "./translations/i18n"
import { useBackendRequest } from "./useBackendRequest"
import { useRootSelector } from "./useRootSelector"

import type { FlatListProps, HelpContentStatic } from "./components"
import type { DeviceIdProps, PermissionCheckProps } from "./types"

export const HELP_CONTENT: HelpContentStatic = {
  bodyElement: i18next.t("helpBodyText", {
    ns: "createDeviceConnection",
  }),
  subject: "device_connections",
  titleElement: i18n.t("aboutDeviceConnections", {
    ns: "createDeviceConnection",
  }),
}
export function useDeviceConnectionsListData() {
  return useRootSelector((state) => {
    const allTriggers = Models.trigger.selectAll(state)
    allTriggers.sort((a, b) => {
      return a.sourceDeviceId.localeCompare(b.sourceDeviceId)
    })

    return allTriggers.filter((trigger) => {
      if (typeof trigger.targetDeviceId === "string") {
        return true
      }
      return false
    })
  })
}

export function useDeleteDeviceConnection(props: {
  onDeleteSuccess?: () => void
}) {
  const { handleError, isLoading, sendRequest, toasts } = useBackendRequest(
    deleteEventActionTriggersAsync,
  )

  return {
    handleSubmit: (params: {
      connectionId: number
      sourceDeviceId: string
    }) => {
      //
      sendRequest({
        deviceId: params.sourceDeviceId,
        ids: [params.connectionId],
        kind: "automation",
      })
        .then(() => {
          if (props.onDeleteSuccess) {
            props.onDeleteSuccess()
          }
          return toasts.success()
        })

        .catch((error) =>
          handleError(error, {
            toastMessage: "default",
          }),
        )
    },
    isLoading,
  }
}
/**
 * Delete a device connection
 */
export function ConfirmDeleteDeviceConnection({
  connectionId,
  onCancel,
  onDeleteSuccess,
  sourceDeviceId,
}: PermissionCheckProps & {
  connectionId: number
  onCancel: () => void

  sourceDeviceId: string
  onDeleteSuccess?: () => void
}) {
  const { handleSubmit, isLoading } = useDeleteDeviceConnection({
    onDeleteSuccess,
  })

  return (
    <React.Fragment>
      <TitleWithIcon
        IconComponent="Warning"
        titleText={i18n.t("deviceConnections:deleteConnection")}
        subheaderElement={i18n.t(
          "deviceConnections:areYouSureYouWantToDisconnectTheseDevices",
        )}
      />
      <Row mt="$4">
        <Box flex={1} mr="$2">
          <CancelButton isDisabled={isLoading} onPress={onCancel} />
        </Box>
        <SubmitButton
          flex={1}
          isLoading={isLoading}
          onPress={() => handleSubmit({ connectionId, sourceDeviceId })}
        />
      </Row>
    </React.Fragment>
  )
}

/**
 * Component to toggle notifications for a device connection
 */
function ToggleNotify({
  connectionId,
  currentValue,
}: {
  connectionId: number
  currentValue: boolean
}) {
  const { handleError, sendRequest, toasts } = useBackendRequest(
    updateDeviceConnectionAsync,
  )

  const handleChange = () => {
    let nextValue: boolean
    let successMessage: string
    if (currentValue) {
      nextValue = false
      successMessage = i18n.t("deviceConnections:notificationsDisabled")
    } else {
      nextValue = true
      successMessage = i18n.t("deviceConnections:notificationsEnabled")
    }

    sendRequest({
      connectionId,
      notify: nextValue,
    })
      .then(() => toasts.success(successMessage))
      .catch((error) => handleError(error, { toastMessage: "default" }))
  }
  const getValue = useBreakpointValue()
  const iconElement = renderIconFromIconProp("NotificationsOn", {
    colorScheme: "secondary",
    size: ICON_SIZES.$2xl,
  })
  return (
    <Row>
      <Box mr="$2">
        {getValue({
          base: iconElement,
          sm: (
            <TextWithIcon
              IconComponent={iconElement}
              text={i18n.t("deviceConnections:sendNotificationsToUsers")}
            />
          ),
        })}
      </Box>
      <Box>
        <Switch value={currentValue} onValueChange={handleChange} />
      </Box>
    </Row>
  )
}

/**
 * Dialog to confirm deletion of a device connection
 */
function DeleteConnectionDialog(props: {
  onCancelDelete: () => void
  onSuccess: () => void
  toDeleteId: number
}) {
  const { handleSubmit, isLoading } = useDeleteDeviceConnection({
    onDeleteSuccess: props.onSuccess,
  })
  const toDelete = useRootSelector((state) => {
    return Models.trigger.selectById(state, props.toDeleteId)
  })
  if (!toDelete) {
    return null
  }

  return (
    <AlertDialog
      isOpen
      titleElement={i18n.t("deviceConnections:deleteConnection")}
      actionElement={
        <ActionButtons
          isLoading={isLoading}
          orientation="horizontal"
          onPressCancel={props.onCancelDelete}
          onPressSubmit={() => {
            handleSubmit({
              connectionId: toDelete.id,
              sourceDeviceId: toDelete.sourceDeviceId,
            })
          }}
        />
      }
      onClose={props.onCancelDelete}
    >
      <AlertDialogScrollView>
        <TextWithIcon
          IconComponent="Warning"
          text={i18n.t(
            "deviceConnections:areYouSureYouWantToDisconnectTheseDevices",
          )}
        />
        <Box my="$4">
          <DeviceConnectionDisplay {...toDelete} />
        </Box>
      </AlertDialogScrollView>
    </AlertDialog>
  )
}

type ItemType = ReturnType<typeof useDeviceConnectionsListData>[number]

const keyExtractor = (item: { id: number }): string => `${item.id}`

const ItemSeparatorComponent = () => <Box mb="$4" />

const styles = StyleSheet.create({
  contentContainer: {
    paddingBottom: SPACING.$toolbar,
  },
})

export function DeviceConnectionsList({
  onPressCreateConnection,
  onPressDevice,
  withPermissions,
  ...rest
}: Omit<FlatListProps<ItemType>, "data" | "renderItem"> &
  PermissionCheckProps & {
    onPressCreateConnection: () => void
    onPressDevice: (params: DeviceIdProps & { codaDeviceAlias: string }) => void
  }) {
  const data = useDeviceConnectionsListData()
  const [idToDelete, setIdToDelete] = React.useState<number>()
  const handlePressCreateConnection = withPermissions({
    callback: onPressCreateConnection,
    required: "canManageCustomTriggers",
  })
  const addConnectionButton = (
    <Button
      IconComponent="Add"
      style={{ marginLeft: "auto" }}
      text={i18n.t("deviceConnections:connectDevices")}
      onPress={handlePressCreateConnection}
    />
  )
  const onCancelDelete = () => setIdToDelete(undefined)
  // TODO: Implement a FAB for adding a new connection
  return (
    <React.Fragment>
      {typeof idToDelete === "number" ? (
        <DeleteConnectionDialog
          toDeleteId={idToDelete}
          onCancelDelete={onCancelDelete}
          onSuccess={onCancelDelete}
        />
      ) : null}
      <FlatList
        {...rest}
        ItemSeparatorComponent={ItemSeparatorComponent}
        contentContainerStyle={styles.contentContainer}
        data={data}
        id="device-connections"
        keyExtractor={keyExtractor}
        ListEmptyComponent={
          <Container>
            <NoListItems
              message={i18n.t("deviceConnections:deviceConnectionsListEmpty")}
              action={
                data.length === 0 && Platform.OS !== "web"
                  ? addConnectionButton
                  : null
              }
            />
          </Container>
        }
        ListHeaderComponent={
          data.length > 0 ? (
            <Background px="$4" py="$2">
              {addConnectionButton}
            </Background>
          ) : null
        }
        renderItem={({ item }) => {
          return (
            <ListItemCard>
              <DeviceConnectionDisplay
                onPressDevice={onPressDevice}
                {...item}
              />
              <Row
                flexWrap="wrap-reverse"
                justifyContent="space-between"
                mt="$2"
              >
                <Box>
                  <Button
                    IconComponent={AppIcons.Disconnect}
                    size="sm"
                    text={i18n.t("deviceConnections:disconnect")}
                    variant="danger"
                    onPress={withPermissions({
                      callback: () => {
                        return setIdToDelete(item.id)
                      },
                      required: "canManageCustomTriggers",
                    })}
                  />
                </Box>
                <ToggleNotify
                  connectionId={item.id}
                  currentValue={item.notify === true}
                />
              </Row>
            </ListItemCard>
          )
        }}
        {...rest}
      />
    </React.Fragment>
  )
}
