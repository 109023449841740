import type { TargetDatabaseName } from "./Internal"
import type { LogLevelInput } from "./logger"

import type { RootThunkExtra, TestUser } from "./Requests"

import type { FarmUserPermissionName, FarmUserPermissions } from "./permissions"
import type { SensorConfigKey } from "./sensor-configurations"

import type { FarmJoinCodeStatus, PhoneNumber } from "./models"
import type { SeedDatabasePreset } from "./SeedDatabase"
import type { AnySensorKey } from "./sensors"

import type { Auth } from "aws-amplify"

import type { FarmAccountProperties } from "./geocoding"

import type { SetDifference, ValuesType } from "utility-types"
import type { Paths } from "../web/src/pages/base/paths"
import type {
  DeviceProfileStackParamList,
  DevicesStackParamList,
  FieldProfileStackParamList,
  MoreStackParamList,
  RootStackParamList,
  RootTabParamList,
} from "../mobile/src/screens/components/navigation"
export type AddDeviceErrorCode =
  | "ALREADY_ADDED"
  | "ALREADY_CLAIMED"
  | "EXPIRED"
  | "INVALID"

export const ADD_DEVICE_INPUT_MODE = {
  coda_device_alias: "coda_device_alias",
  device_share_code: "device_share_code",
} as const
export type AddDeviceInputMode = ValuesType<typeof ADD_DEVICE_INPUT_MODE>

export type DeviceProfilePageId = ValuesType<
  (typeof Paths)["devices"]["profile"]["pages"]
>["id"]

export type AlertSeverity = "error" | "info" | "success" | "warning"

import type Eas from "../mobile/eas.json"
import type { FieldActionName } from "./fields.reducer"
export type MobileEnvironmentName = SetDifference<
  keyof (typeof Eas)["build"],
  "_base" | "_internal"
>

export function getNodeEnv(): "development" | "production" {
  return __DEV__ ? "development" : "production"
}

export type StatusMapFeatureName =
  | "change-farm-account"
  | "item-list"
  | "quick-actions"

export type AuthFieldName =
  | "code-string"
  | "confirm-password"
  | "email"
  | "new-password"
  | "password"

export type AuthPageId =
  | "confirm-sign-up"
  | "password-reset"
  | "sign-in"
  | "sign-up"
export type CognitoRequestName = keyof typeof Auth &
  (
    | "changePassword"
    | "confirmSignUp"
    | "currentSession"
    | "forgotPassword"
    | "forgotPasswordSubmit"
    | "resendSignUp"
    | "signIn"
    | "signOut"
    | "signUp"
  )

export type FormFieldName =
  | AddDeviceInputMode
  | AuthFieldName
  | FarmUserPermissionName
  | SensorConfigKey
  | keyof FarmAccountProperties
  | "admin-mode-switch"
  | "body"
  | "codeString"
  | "country"
  | "deviceName"
  | "email"
  | "featureName"
  | "feedbackText"
  | "fieldName"
  | "name"
  | "notificationString"
  | "phoneNumber"
  | "rawValue"
  | "share-code"
  | "subject"
  | "username"
  | "value"

export type AppFeatureName =
  | FieldActionName
  | StatusMapFeatureName
  | keyof DeviceProfileStackParamList
  | keyof DevicesStackParamList
  | keyof FieldProfileStackParamList
  | keyof MoreStackParamList
  | keyof MoreStackParamList
  | keyof RootStackParamList
  | keyof RootTabParamList
  | "configured-sensors-list"
  | "confirm-delete-trigger"
  | "farm-join-code"
  | "new-farm-success"

export type AppFeedbackKey = AppFeatureName | "app" | "other"
export type HelpKey =
  | AddDeviceInputMode
  | AnySensorKey
  | FarmUserPermissionName
  | keyof DeviceProfileStackParamList
  | keyof DevicesStackParamList
  | keyof MoreStackParamList
  | keyof MoreStackParamList
  | keyof RootStackParamList
  | "farm-join-code"

export interface HelpScreenParams {
  helpKey: HelpKey
  deviceId?: string | null | undefined
}

export interface AppConfigExtra {
  EAS_BUILD_GIT_COMMIT_HASH: string | null | undefined
  EAS_BUILD_ID: string | null | undefined
  EAS_BUILD_MAVEN_CACHE_URL: string | null | undefined
  EAS_BUILD_NPM_CACHE_URL: string | null | undefined
  EAS_BUILD_PLATFORM: string | null | undefined
  EAS_BUILD_PROFILE: string | null | undefined
  EAS_BUILD_RUNNER: string | null | undefined
  EAS_BUILD_STATUS: string | null | undefined
  EAS_BUILD_USERNAME: string | null | undefined
  EAS_BUILD_WORKDINGDIR: string | null | undefined
  ENVIRONMENT_NAME: MobileEnvironmentName | null | undefined
  IS_END_TO_END_TEST: boolean
  LOCALHOST: string
  LOG_LEVEL: LogLevelInput
  TARGET_DATABASE_NAME: TargetDatabaseName
  TEST_USER: TestUser | null | undefined
  ENABLE_REDUX_LOGGER?: boolean | null
  TEST_USERS?: { [key: string]: unknown } | undefined
}
export interface SeedDatabaseOptions {
  activeFarmJoinCodeStatus: FarmJoinCodeStatus | null
  activeUserIsAdmin: boolean | null
  activeUserPermissions: FarmUserPermissions
  presetName: SeedDatabasePreset
  termsOfServiceAccepted: boolean | null
  userPhoneNumbers: Array<Pick<PhoneNumber, "e164" | "isVerified">> | null
  usesMetricSystem: boolean | null
}

export interface SeedDatabaseParams extends RootThunkExtra {
  authToken: string
  isWeb: boolean
  options: SeedDatabaseOptions
  shouldReloadPage: boolean
}

export const AWS_REGION = "us-west-2" as const
export const REQUEST_BLOCKED_IN_DEMO = "REQUEST_BLOCKED_IN_DEMO"

export const SEED_DATABASE_PHONE_NUMBER = {
  e164: "+15555555555",
  isVerified: true,
}

export const SEED_DATABASE_DEFAULTS: SeedDatabaseOptions = {
  activeFarmJoinCodeStatus: "ACTIVE",
  activeUserIsAdmin: false,
  activeUserPermissions: {
    canControlDevicesRemotely: true,
    canManageCustomTriggers: true,
    canManageDeviceConfiguration: true,
    canManageDeviceNotifications: true,
    canManageDevicePermissions: true,
    canManageFarmAccount: true,
    canManageFields: true,
    canManageJoinCodes: true,
    canManageSchedules: true,
    canManageUserPermission: true,
  },
  presetName: "demo",
  termsOfServiceAccepted: true,
  userPhoneNumbers: [{ ...SEED_DATABASE_PHONE_NUMBER }],
  usesMetricSystem: false,
}

export type SupportImageName =
  | "8x_mounting_screw.png"
  | "app_reel_added_to_map.png"
  | "app_switch_controls.png.png"
  | "battery_with_ring_terminals.png"
  | "coda-device-alias.png"
  | "device_controls.png"
  | "device_location.png"
  | "device_pairing.png"
  | "device_tc3_back_view.png"
  | "device_tc3_blue_status_light.png"
  | "device_tc3_bottom_view.png"
  | "device_tc3_right_side_view.png"
  | "device_tc3_top_view.png"
  | "draw_field.png"
  | "edit_configuration.png"
  | "edit_field.png"
  | "electrical_bypass_switch.png"
  | "electrical_disconnect_off.png"
  | "electrical_disconnect_on.png"
  | "enclosure_front.png"
  | "events.png"
  | "farmHQ.png"
  | "fc_2_fuse.png"
  | "FC_2_Labeled.png"
  | "FC_2_status_light.png"
  | "FC_2_status_lights.png"
  | "FC-2-pump.jpg"
  | "fc2_install_package.png"
  | "fc2Pro.png"
  | "field_options.png"
  | "field_overview.png"
  | "fuse.png"
  | "grommet.png"
  | "hadware_pressure_cable_V2.png"
  | "hall_sensor_assembly.png"
  | "hall_sensor_cable.png"
  | "hall_sensor_gap.png"
  | "hall_sensor_mounting_hardware.png"
  | "hall_sensor_painted.png"
  | "hall_sensor_sharpie.png"
  | "hardware_6x_self_threading_screws.png"
  | "hardware_27_drill_bit.png"
  | "hardware_bent_sensor_bracket_V2.png"
  | "hardware_flat_sensor_bracket_V2.png"
  | "hardware_gel_boxes.png"
  | "hardware_hall_sensor_bracket_V2.png"
  | "hardware_hall_sensor_mounting_screws.png"
  | "hardware_hall_sensor_V2.png"
  | "hardware_magnet_plastic_case.png"
  | "hardware_pressure_transducer_V2.png"
  | "hardware_pump_kit.png"
  | "hardware_reel_kit.png"
  | "hardware_ring_terminals.png"
  | "hardware_tc3_device_kit.png"
  | "hardware_tc3_device.png"
  | "hardware_tc3_fuse.png"
  | "hardware_tc3_hall_sensor_cable.png"
  | "hardware_tc3_m4x8_setscrew.png"
  | "hardware_tc3_power_cable.png"
  | "hardware_tc3_switch_cable.png"
  | "hardware_tc3_switch_cable.png"
  | "hardware_wago_connectors.png"
  | "hose_diameter.png"
  | "hose_width.png"
  | "index.ts"
  | "install_hall_sensor_bracket.png"
  | "install_hall_sensor_magnet_distance.png"
  | "install_hall_sensor1_connection.png"
  | "install_measuring_magnets.png"
  | "install_pressure_sensor_V2.png"
  | "install_pressure_sensor1_connection.png"
  | "install_pressure_transducer_pipe.png"
  | "install_tc3_connecting_wire_harness.png"
  | "install_tc3_drilling_mounting_holes.png"
  | "install_tc3_m4_setscrew.png"
  | "install_tc3_marking_mounting_holes.png"
  | "install_tc3_power_cable.png"
  | "install_tc3_power_cable.png"
  | "install_tc3_remove_bracket.png"
  | "install_tc3_screwing_in_bracket.png"
  | "irrigation_boom.png"
  | "irrigation_gun.png"
  | "logo.svg"
  | "m12_cable.png"
  | "magnet_installation_positions.png"
  | "magnetic_sensor_led.png"
  | "magnets.png"
  | "managing_farm.png"
  | "managing_field.png"
  | "mounting_bracket.png"
  | "mounting_screw 2.png"
  | "mounting_screw_2.png"
  | "murphy_switch.png"
  | "NC_shutdown_circuit.png"
  | "NC.png"
  | "new_device.png"
  | "new_field.png"
  | "new_pair.png"
  | "new_user.png"
  | "NO_shutdown_circuit.png"
  | "NO_vs_NC.png"
  | "NO.png"
  | "notifications.png"
  | "nozzle_diameter.png"
  | "number_of_magnets.png"
  | "number_pairs.png"
  | "outer_hose_wrap_radius.png"
  | "outer_wraps.png"
  | "pc_1_fuse.png"
  | "pc_1_labeled.png"
  | "pc_1_mounted.png"
  | "PC_1_status_lights.png"
  | "pc_1_switch_terminals.png"
  | "permissions.png"
  | "placed_hall_sensor_sharpie.png"
  | "power_button.png"
  | "power_cable_ring_terminals.png"
  | "power_cable.png"
  | "pressure_sensor_installed.png"
  | "pressure_sensor.png"
  | "pressure_switch_cable.png"
  | "pressure_switch_installed.png"
  | "pressure_switch_with_cable.png"
  | "pressure_switch.png"
  | "pump_install_overview.png"
  | "reel_install_overview.png"
  | "reel_measurements_1.png"
  | "reel_measurements_2.png"
  | "reel_outer_hose_wraps.png"
  | "reel_radius.png"
  | "reel_width.png"
  | "remote_controls.png"
  | "ring_terminals.png"
  | "seametrics_flow_meter_inside.png"
  | "seametrics_flow_meter.png"
  | "share_join_code.png"
  | "solar-panel.png"
  | "spool_width.png"
  | "swath_estimate.png"
  | "tc3_mounted.jpg"
  | "wiring_diagram_tc3_NC.png"
  | "wiring_diagram_tc3_NO.png"
  | "wiring_diagram_tc3_pump.png"
  | "wiring_diagram_tc3_relay_nc.png"
  | "wiring_diagram_tc3_vfd_enable.png"
  | "wiring_diagram_tc3_vfd_relay.png"
  | "wiring_tc3_external_power_supply.png"
  | "zipTies.png"
export const PIVOT_DIRECTIONS = [
  "pivot_clockwise",
  "pivot_counterclockwise",
] as const
export type PivotDirection = (typeof PIVOT_DIRECTIONS)[number]
