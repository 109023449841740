import { Platform } from "react-native"

import { isTruthyString } from "../type-guards"

import type {
  AlertSeverity,
  AppFeatureName,
  AuthPageId,
  DeviceProfilePageId,
  FormFieldName,
  HelpKey,
} from "../constants"
import type { MapItemType } from "../geo"

import type { FarmUserPermissionName } from "../permissions"
import type { InstallationTypeName, SensorName } from "../sensor-configurations"
import type { AnySensorKey } from "../sensors"
import type { SupportPageId, SupportProductId } from "../Support"
import type { TriggerFilterKey } from "../triggers.reducer"
import type { RelayActionType } from "../device-commands.reducer"
import type { FieldActionName } from "../fields.reducer"
import type { AnyScreenId } from "../../mobile/src/screens/components/navigation"
export type DashboardPageId =
  | "account"
  | "admin"
  | "app-notifications"
  | "create-farm"
  | "dev"
  | "device-connections"
  | "device-profile"
  | "devices"
  | "farm"
  | "field-irrigation-history"
  | "fields"
  | "join-farm-with-code"
  | "manage-users"
  | "schedules"
  | "status-map"
  | "support"
  | "view-terms-of-service"

export type PageId = AuthPageId | DashboardPageId

export type MakeLinkId<T extends string> = `${T}-link`
export type LinkId = MakeLinkId<
  | DeviceProfilePageId
  | PageId
  | SupportPageId
  | "contact-us"
  | "create-configuration"
  | "create-custom-automation"
  | "debug-info"
  | "field-profile-landing"
  | "manage-field"
  | "manage-permissions"
  | "map-settings"
  | "set-reel-distance"
  | "set-run-direction"
  | "sign-out"
  | "view-permissions"
>
type ComponentIds =
  | "dialog"
  | "nav-menu"
  | "sidebar-content"
  | "sidebar-links"
  | "sidebar-root"
type ButtonId = `${
  | FieldActionName
  | "add-device"
  | "add-more-devices"
  | "add-phone-number"
  | "add-sensor"
  | "cancel"
  | "center-pivot"
  | "close"
  | "create-connection-link"
  | "customize-notification"
  | "decline"
  | "delete-pair"
  | "delete-sensor"
  | "dislike"
  | "done"
  | "force-update"
  | "help"
  | "like"
  | "metric"
  | "nav-menu-open"
  | "new-join-code"
  | "newer"
  | "older"
  | "open-field-roster-actions"
  | "phone-number-options-menu"
  | "polygon"
  | "print"
  | "quick-actions"
  | "reload-events"
  | "remove-device"
  | "rename-device"
  | "resend-code"
  | "reset-device"
  | "set-center-pivot-path-link"
  | "set-linear-path-link"
  | "set-up-device"
  | "show-devices"
  | "show-fields"
  | "show-more"
  | "sidebar-toggle"
  | "skip-for-now"
  | "submit"
  | "switch-controls-close"
  | "switch-controls-toggle"
  | "switch-farms"
  | "test-message"
  | "toggle-theme"
  | "us"
  | "user-account-menu-toggle"
  | "verify-phone-number"
  | `${"next" | "previous"}-${"device" | "field" | "reel-run"}`
  | `${Lowercase<RelayActionType>}`
  | `close-${
      | "device-commands"
      | "dialog"
      | "field-actions"
      | "page"
      | "sidebar"}`
  | `create-${"custom-notification" | "field" | "trigger"}`
  | `delete${`-automation` | `-notification` | `-phone-number` | ``}`
  | `edit${`-configuration` | `-sensor` | ``}`
  | `farm-join-code-copy`
  | `feedback`
  | `focus-${MapItemType}`
  | `show-${SensorName}-sensor`}-btn`

export type TestId =
  | AlertSeverity
  | AnyScreenId
  | AnySensorKey
  | AppFeatureName
  | AuthPageId
  | ButtonId
  | ComponentIds
  | DashboardPageId
  | FarmUserPermissionName
  | FieldActionName
  | FormFieldName
  | HelpKey
  | InstallationTypeName
  | LinkId
  | PageId
  | SensorName
  | SupportPageId
  | "account-locked-due-to-nonpayment"
  | "account"
  | "active-farm"
  | "add-device-form"
  | "add-phone-number"
  | "admin"
  | "app-bar"
  | "app"
  | "appLanguageSelect"
  | "background"
  | "battery-indicator"
  | "checkbox"
  | "choose-installation-type"
  | "choose-pressure-sensor-model"
  | "coda-farm-tech"
  | "coda-section"
  | "copy-location-button"
  | "create-support-ticket"
  | "customize-notification-form"
  | "cycle-left"
  | "cycle-right"
  | "dashboard"
  | "delete-phone-number-confirmation"
  | "dev"
  | "device-commands-history-list"
  | "device-configuration"
  | "device-event-timestamp"
  | "device-in-field"
  | "device-marker"
  | "device-notifications-list"
  | "device-notifications-toggle-denied"
  | "device-profile"
  | "device-status-indicators"
  | "devices"
  | "enter-email-form"
  | "experimental-feature-warning"
  | "farm-join-code"
  | "feedback-form"
  | "field-actions"
  | "floating-map-card"
  | "flow-indicator"
  | "flow-sensor-yes-or-no"
  | "forbidden"
  | "form-error"
  | "google-maps-link"
  | "gps-indicator"
  | "hallSwitch-indicator"
  | "help-content"
  | "help-text"
  | "input"
  | "installation-type-indicator"
  | "instructions"
  | "internal-only"
  | "load-error"
  | "loader"
  | "manage-farm"
  | "max-date"
  | "measurement-preference"
  | "min-date"
  | "no-list-items"
  | "notify-checkbox"
  | "other-farms"
  | "page-404"
  | "page-500"
  | "page-content"
  | "page-header"
  | "page-title"
  | "password-visibility-checkbox"
  | "permission-text"
  | "permissions"
  | "phone-number-options-menu"
  | "phone-numbers-list-link"
  | "pressure-indicator"
  | "pressure-sensor-yes-or-no"
  | "pressure-slider"
  | "preview"
  | "print-table-of-content"
  | "radius-input"
  | "relay-indicator"
  | "rename-device-form"
  | "review-choices"
  | "root-loader"
  | "run-completion-pct"
  | "run-eta-indicator"
  | "run-progress"
  | "run-speed-indicator"
  | "search"
  | "selected-device"
  | "sensor-buttons"
  | "sensorName"
  | "set-device-permissions"
  | "set-farm-permissions"
  | "set-vfd-speed-form"
  | "source-device-select"
  | "source-sensor-select"
  | "source-sensor"
  | "subscription-due-reminder"
  | "subscription-overdue-warning"
  | "success"
  | "support-page-print-all"
  | "support-table-of-contents"
  | "support"
  | "switch-controls"
  | "terms-of-service"
  | "toast-container"
  | "toggle-switch"
  | "toolbar"
  | "unconfigured-device-warning"
  | "unknown-fields"
  | "unread-indicator"
  | "user-account-menu"
  | "user-email"
  | "voltage-indicator"
  | "welcome-dialog"
  | `${MapItemType}-cycler`
  | `${SensorName}-sensor`
  | `${SupportProductId}-link`
  | `azimuth-${`slider` | `text`}`
  | `confirm-delete-${TriggerFilterKey}`
  | `create-${InstallationTypeName}`
  | `create-device-comment${"-link" | ""}`
  | `device-location${"-link" | ""}`
  | `device-notifications-list-item-${number}`
  | `device-notifications-toggle-${`disabled` | `enabled`}`
  | `device-status-list-item-${number}`
  | `devices-list-item-${number}`
  | `fields-list-item-${number}`
  | `notification-message-item-${number}`
  | `phone-numbers-list-item-${number}`
  | `source-sensor-state-${"current" | "previous"}${"-select" | ""}`
  | `switch-control-${number | "link"}`
  | `target-${"action" | "device"}-select`
  | `unknown-fields-list-item-${number}`

export interface TestIdOptions {
  preserveId?: boolean
}
export interface TestIdProps {
  id: TestId
  preserveId?: boolean
}
export type WithTestId<T, Id = TestId> = Omit<
  T,
  "id" | "nativeID" | "testID"
> & {
  id: Id & TestId
  preserveId?: boolean
}
export interface OptionalTestId<Id = TestId> {
  id?: Id & TestId
  preserveId?: boolean
}
export type WithOptionalTestId<T, Id = TestId> = Omit<
  T,
  "id" | "nativeID" | "testID"
> &
  OptionalTestId<Id>
/**
 * Some features depend on ids being present; they are removed unless
 * we opt-in to preserve them
 */
function getShouldPreserveTestIds(options?: TestIdOptions): boolean {
  const shouldPreserve = options?.preserveId === true

  const isCypressRunning =
    Platform.OS === "web" && typeof window.Cypress !== "undefined"

  if (isCypressRunning) {
    // Keep test ids if cypress is running
    return true
  }
  // Some ids should be preserved in production;
  // we can override the default behavior
  if (__DEV__ || shouldPreserve) {
    return true
  }
  return false
}
/**
 * Remove native and test ids in production unless we need them for something
 * @see {@link{https://reactnative.dev/docs/view#nativeid}}
 * @param options
 * @param options.keepInProduction
 * @param id
 */

export function testId(
  id: TestId | undefined,
  options?: TestIdOptions,
): TestId | undefined {
  if (getShouldPreserveTestIds(options)) {
    return id
  }
  return undefined
}
export interface TestIds {
  nativeID?: TestId | undefined
  testID?: TestId | undefined
}
/**
 * Remove native and test ids in production unless we need them for something
 * @see {@link{https://reactnative.dev/docs/view#nativeid}}
 * @param id
 * @param options
 * @param options.keepInProduction
 */

export function testIds(
  id: TestId | undefined,
  options?: TestIdOptions,
): TestIds | undefined {
  const shouldKeep = getShouldPreserveTestIds(options)
  if (shouldKeep && isTruthyString(id)) {
    return { nativeID: id, testID: id }
  }
  return undefined
}
