import React from "react"
import { View } from "react-native"
import { useStyle } from "react-native-style-utilities"

import { RADII } from "./theme/radii"
import { SIZES } from "./theme/sizes"
import { SPACING } from "./theme/spacing"

import type { TestId } from "./test-id"
import type { ViewProps, ViewStyle } from "react-native"
import type { RadiusProp } from "./theme/radii"
import type { SizeToken } from "./theme/sizes"
import type { SpaceToken } from "./theme/spacing"
interface MarginShorthandProps {
  m?: SpaceToken
  mb?: SpaceToken
  ml?: SpaceToken
  mr?: SpaceToken
  mt?: SpaceToken
  mx?: SpaceToken
  my?: SpaceToken
}

interface PaddingShorthandProps {
  p?: SpaceToken
  pb?: SpaceToken
  pl?: SpaceToken
  pr?: SpaceToken
  pt?: SpaceToken
  px?: SpaceToken
  py?: SpaceToken
}
export interface SizingShorthandProps {
  h?: SizeToken
  maxH?: SizeToken
  maxW?: SizeToken
  minH?: SizeToken
  minW?: SizeToken
  w?: SizeToken
}
export interface FlexLayoutProps
  extends Pick<
    ViewStyle,
    | "alignItems"
    | "display"
    | "flex"
    | "flexBasis"
    | "flexDirection"
    | "flexGrow"
    | "flexShrink"
    | "flexWrap"
    | "justifyContent"
    | "zIndex"
  > {
  rounded?: RadiusProp
}

export interface ShorthandPositionProps {
  bottom?: SpaceToken
  left?: SpaceToken
  position?: ViewStyle["position"]
  right?: SpaceToken
  top?: SpaceToken
}

export interface BoxProps
  extends SizingShorthandProps,
    ViewProps,
    MarginShorthandProps,
    PaddingShorthandProps,
    FlexLayoutProps,
    ShorthandPositionProps {
  bg?: string
  id?: TestId
  opacity?: ViewStyle["opacity"]

  overflow?: ViewStyle["overflow"]
  position?: ViewStyle["position"]
  preserveId?: boolean
}
type DimensionValue = number | string

// TODO: Maybe implement this but I am concerned about performance
// const getSpacing = (value: SpaceToken) => {
//   const lookupResult = SPACING[value as keyof typeof SPACING] as
//     | DimensionValue
//     | undefined
//   return lookupResult ?? value
// }
export function Box({
  bg,
  bottom,
  display,
  flex,
  flexBasis,
  flexDirection,
  flexGrow,
  flexShrink,
  h,
  id,
  left,
  m,
  maxH,
  maxW,
  mb,
  minH,
  minW,
  ml,
  mr,
  mt,
  mx,
  my,
  nativeID,
  opacity,
  overflow,
  p,
  pb,
  pl,
  position,
  pr,
  pt,
  px,
  py,
  right,
  rounded,
  style,
  testID,
  top,
  w,
  zIndex,
  ...rest
}: BoxProps) {
  return (
    <View
      nativeID={nativeID ?? id}
      testID={testID ?? id}
      style={useStyle(() => {
        const result: ViewStyle = {
          backgroundColor: bg,
          display,
          flex,
          flexBasis,
          flexDirection,
          flexGrow,
          flexShrink,
          opacity,
          overflow,
          position,
          zIndex,
        }
        if (top) {
          result.top = SPACING[top]
        }
        if (bottom) {
          result.bottom = SPACING[bottom]
        }
        if (left) {
          result.left = SPACING[left]
        }

        if (right) {
          result.right = SPACING[right]
        }
        if (h) {
          result.height = SIZES[h]
        }
        if (w) {
          result.width = SIZES[w]
        }
        if (minH) {
          result.minHeight = SIZES[minH]
        }
        if (minW) {
          result.minWidth = SIZES[minW]
        }

        if (maxH) {
          result.maxWidth = SIZES[maxH]
        }
        if (maxW) {
          result.maxWidth = SIZES[maxW]
        }
        if (m) {
          result.margin = SPACING[m] as DimensionValue
        }
        if (mb) {
          result.marginBottom = SPACING[mb] as DimensionValue
        }
        if (ml) {
          result.marginLeft = SPACING[ml] as DimensionValue
        }
        if (mr) {
          result.marginRight = SPACING[mr] as DimensionValue
        }
        if (mt) {
          result.marginTop = SPACING[mt] as DimensionValue
        }
        if (mx) {
          result.marginHorizontal = SPACING[mx] as DimensionValue
        }
        if (my) {
          result.marginVertical = SPACING[my] as DimensionValue
        }
        if (p) {
          result.padding = SPACING[p] as DimensionValue
        }
        if (pb) {
          result.paddingBottom = SPACING[pb] as DimensionValue
        }
        if (pl) {
          result.paddingLeft = SPACING[pl] as DimensionValue
        }
        if (pr) {
          result.paddingRight = SPACING[pr] as DimensionValue
        }
        if (pt) {
          result.paddingTop = SPACING[pt] as DimensionValue
        }
        if (px) {
          result.paddingHorizontal = SPACING[px] as DimensionValue
        }
        if (py) {
          result.paddingVertical = SPACING[py] as DimensionValue
        }
        if (rounded) {
          result.borderRadius = RADII[rounded]
        }
        return [result, style]
      }, [
        bg,
        bottom,
        display,
        flex,
        flexBasis,
        flexDirection,
        flexGrow,
        flexShrink,
        h,
        left,
        m,
        maxH,
        maxW,
        mb,
        minH,
        minW,
        ml,
        mr,
        mt,
        mx,
        my,
        opacity,
        overflow,
        p,
        pb,
        pl,
        position,
        pr,
        pt,
        px,
        py,
        right,
        rounded,
        style,
        top,
        w,
        zIndex,
      ])}
      {...rest}
    />
  )
}
