import type { Reducer } from "@reduxjs/toolkit"
import { createAction, createSlice, isAsyncThunkAction } from "@reduxjs/toolkit"

import * as Models from "./models"
import { isTruthyString } from "./type-guards"
import { useRootSelector } from "./useRootSelector"

import type { RootState } from "./root.reducer"
import type { TrackedRequest } from "./models"
import type { AnyRequestName } from "./send-request"
interface RequestsState
  extends Models.ModelState<TrackedRequest, AnyRequestName> {
  count: number
}

const initialState: RequestsState = {
  count: 0,
  entities: {},
  ids: [],
}

const adapter = Models.requests.adapter

export const clearRequests = createAction("CLEAR_REQUEST")

export const clearFetchStatus =
  createAction<Array<AnyRequestName | TrackedRequest>>("ClearFetchStatus")

function extractRequestNameFromAction(action: { type?: string }) {
  const [requestName] = action.type?.split("/") ?? []

  if (isTruthyString(requestName)) {
    return requestName as AnyRequestName
  }
  return undefined
}
const slice = createSlice({
  extraReducers: (builder) =>
    builder
      .addCase(clearRequests, () => ({ ...initialState }))
      .addCase(clearFetchStatus, (state, action) => {
        adapter.upsertMany(
          state,
          action.payload.map((value) => ({
            fetchStatus: undefined,
            id: typeof value === "string" ? value : value.id,
          })),
        )
      })

      .addMatcher(isAsyncThunkAction, (state, action) => {
        const id = extractRequestNameFromAction(action)
        if (id) {
          adapter.upsertOne(state, {
            fetchStatus: action.meta.requestStatus,
            id,
          })
        }
      }),
  initialState,
  name: "requests",
  reducers: {},
})

const requests: Reducer<typeof initialState> = slice.reducer
export default requests

export function getFetchStatusByName(state: RootState, id: AnyRequestName) {
  return Models.requests.selectById(state, id)?.fetchStatus
}
/**
 *
 * @param state root state
 * @param id name of the request
 */
export function getIsPendingByRequestNameFromState(
  state: RootState,
  id: AnyRequestName,
) {
  return getFetchStatusByName(state, id) === "pending"
}

export function useIsPending(...requestNames: AnyRequestName[]): boolean {
  return useRootSelector((state) => {
    return requestNames.some((requestName) =>
      getIsPendingByRequestNameFromState(state, requestName),
    )
  })
}
