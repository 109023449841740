import React from "react"
import { useTranslation } from "react-i18next"
import { StyleSheet } from "react-native"

import { clickCancel } from "./actions"
import { getCurrentBreakpointKey } from "./app-state.reducer"
import { Box, Button, Row } from "./components"
import { useRootDispatch } from "./useRootDispatch"
import { useRootSelector } from "./useRootSelector"

import type { BoxProps, ButtonProps, IconProp } from "./components"
export interface ActionButtonProps extends BoxProps {
  isLoading: boolean
  onPressSubmit: () => unknown
  SubmitIconComponent?: IconProp
  SubmitIconPosition?: ButtonProps["iconPosition"]
  buttonSize?: ButtonProps["size"]
  cancelText?: string
  isCancelDisabled?: boolean
  isDisabled?: boolean
  isSubmitDisabled?: boolean
  onPressCancel?: () => unknown
  orientation?: "horizontal" | "vertical"
  submitText?: string
}

const styles = StyleSheet.create({
  common: {
    flexGrow: 1,
    minWidth: 120,
  },
})

export function ActionButtons({
  SubmitIconComponent,
  SubmitIconPosition,
  buttonSize = "lg",
  cancelText,
  isCancelDisabled = false,
  isDisabled = false,
  isLoading,
  isSubmitDisabled = false,
  mt = "$4",
  onPressCancel,
  onPressSubmit,
  orientation,
  submitText,
  ...rest
}: ActionButtonProps): React.JSX.Element {
  const dispatch = useRootDispatch()
  const { t } = useTranslation()

  const submitButton = (overrides?: ButtonProps) => (
    <Button
      IconComponent={SubmitIconComponent}
      iconPosition={SubmitIconPosition}
      id="submit-btn"
      isDisabled={isLoading || isSubmitDisabled || isDisabled}
      isLoading={isLoading}
      size={buttonSize}
      style={styles.common}
      text={submitText ?? t("submit")}
      variant="primary"
      onPress={onPressSubmit}
      {...overrides}
    />
  )
  const cancelButton = (overrides?: ButtonProps) => (
    <Button
      id="cancel-btn"
      isDisabled={isLoading || isCancelDisabled || isDisabled}
      size={buttonSize}
      style={styles.common}
      text={cancelText ?? t("cancel")}
      variant="outline"
      onPress={onPressCancel ?? (() => dispatch(clickCancel()))}
      {...overrides}
    />
  )
  const breakpointKey = useRootSelector(getCurrentBreakpointKey)
  const isSmallScreen = breakpointKey === "xs"
  // console.log({ breakpointKey, isSmallScreen, orientation })
  if (
    orientation === "vertical" ||
    (isSmallScreen && orientation !== "horizontal")
  ) {
    return (
      <Box mt={mt} {...rest}>
        <Box mb="$4">{submitButton()}</Box>
        {cancelButton({ variant: "text" })}
      </Box>
    )
  }
  return (
    <Row alignItems="center" flexWrap="nowrap" mt={mt} {...rest}>
      <Box flexGrow={1} mr="$4">
        {cancelButton(isSmallScreen ? { size: "lg" } : undefined)}
      </Box>
      <Box flexGrow={1}>
        {submitButton(isSmallScreen ? { size: "lg" } : undefined)}
      </Box>
    </Row>
  )
}
