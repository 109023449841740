import React from "react"

import { createAsyncThunk } from "@reduxjs/toolkit"
import { captureException } from "@sentry/core"

import { FormControl } from "./components"
import { Select } from "./components/form-control/Select"
import { sendRequest } from "./send-request"
import i18n, { SUPPORTED_APP_LANGUAGE_CODES } from "./translations/i18n"
import { useErrorHandler } from "./useErrorHandler"
import { getActiveFarmIdFromState } from "./user-farms.selectors"
import { useRootDispatch } from "./useRootDispatch"
import { handleReloadApp } from "./utility"

import type { RootThunkConfig } from "./root.reducer"
import type { GetActionArgumentsType } from "./send-request"
import type { SupportedAppLanguageCode } from "./translations/i18n"
/**
 *
 */
export const setLanguagePreferenceOverrideForUser = createAsyncThunk<
  void,
  GetActionArgumentsType<"SetLanguagePreferenceOverrideForUser">,
  RootThunkConfig
>(
  "SetLanguagePreferenceOverrideForUser",
  async (actionArguments, { extra, getState }) => {
    try {
      const state = getState()
      const activeFarmId = getActiveFarmIdFromState(state)
      await Promise.all([
        i18n.changeLanguage(actionArguments.languageCode),
        sendRequest("SetLanguagePreferenceOverrideForUser", {
          ...extra,
          actionArguments,
          activeFarmId,
        }),
      ])
    } catch (error) {
      captureException(error)
      throw error
    }
  },
)
const LANGUAGE_SELECT_OPTIONS = SUPPORTED_APP_LANGUAGE_CODES.map((key) => {
  return {
    label: i18n.t(`supportedLanguages.${key}`, { ns: "account" }),
    value: key,
  }
})
export function AppLanguageSelect({
  onSuccess,
}: {
  onSuccess?: () => void
}): React.JSX.Element {
  const dispatch = useRootDispatch()
  const handleError = useErrorHandler()
  const labelText = i18n.t("supportedLanguagesTitle", { ns: "account" })
  return (
    <FormControl.Provider id="appLanguageSelect" w="$full">
      <FormControl.Label>{labelText}</FormControl.Label>
      <Select
        label={labelText}
        options={LANGUAGE_SELECT_OPTIONS}
        selectedValue={i18n.language}
        onValueChange={(nextValue) => {
          dispatch(
            setLanguagePreferenceOverrideForUser({
              languageCode: nextValue as SupportedAppLanguageCode,
            }),
          )
            .then(onSuccess)
            .then(handleReloadApp)
            .catch((error) => handleError(error, { toastMessage: "default" }))
        }}
      />
    </FormControl.Provider>
  )
}
